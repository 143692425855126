import React, { useState, useEffect } from 'react';
import heroHinge from '../images/hero_hinge.webp';
import fireCad from '../images/firecad.png';
import balsaCad from '../images/balsa_cad.webp';
import guitarCad from '../images/guitar_cad.webp';
import heroSuspension from '../images/hero_suspension.webp';
import MCACAD from '../images/mca.png';
import Rocket from '../images/rocket_cad.webp';
import Navigate from '../images/NavigateUCSD.png';
import NWPEEEC from '../images/NWPEEextra.png';
import Whirl from '../images/Whirl.png';
import one from '../images/108.png';
import Regen from '../images/Regen.png';
import actuator from '../images/Actuator.png';
import sole from '../images/solenoid.png';
import daq from '../images/daq.png';
import sdcta from '../images/sdcta.png';
import ysc from '../images/YSC.png';
import tesla from '../images/tesla.webp';
import rtx from '../images/rtx.png';
import nasa from '../images/nasalogo.png';
import tse from '../images/tselogo.png';
import robot from '../images/trirob.png';
import multiscale from '../images/multiscale_logo.png';
import seds from '../images/seds.png';

const Projects = () => {
  const [activeSection, setActiveSection] = useState('experience');
  
  const experiences = [
    {
      title: 'EPM/SWE - Full Stack, CV, and ML',
      company: 'Tesla',
      duration: 'Aug 2024 – present',
      link: 'https://www.tesla.com',
      image: tesla
    },
    {
      title: 'Avionics SWE Intern',
      company: 'RTX (Collins Aerospace)',
      duration: 'Jun 2023 – Sep 2023',
      description: 'Developed test suite for AFDX Filter Driver software using Python, Wireshark, Robot Framework.',
      link: 'https://www.rtx.com',
      image: rtx
    },
    {
      title: 'Propulsion Software Engineer',
      company: 'SEDS@UCSD',
      duration: 'Sep 2021 – Jul 2024',
      description: 'Optimized cooling channels via MATLAB, used Arduino to have motor control systems, and engineered data processing backend. Also Worked on obtaining sponsorships, deliverables, and grants to fund rocket manufacturing, and collaborated with the Halya Team on funding and purchasing parts for engines, structures, and plumbing.',
      link: 'https://www.sedsucsd.org',
      image: seds
    },
    {
      title: 'Developer & Product Manager',
      company: 'Triton Software Engineering',
      duration: 'Sep 2021 – Mar 2023',
      description: 'Developed scaling strategy, contributed to mobile app UI, and created data visualization website for multiple non-profits such as Y Stem & Chess and San Diego County Taxpayers Association.',
      link: 'https://tse.ucsd.edu',
      image: tse
    },
    {
      title: 'Mechanical Engineer',
      company: 'Triton Robotics',
      duration: 'Mar 2021 – Mar 2023',
      description: 'Designed components, repaired electronics, and optimized motor efficiency.',
      link: 'https://tritonrobotics.ucsd.edu',
      image: robot
    },
    {
      title: 'Undergraduate Researcher',
      company: 'Multiscale Flow Physics Laboratory',
      duration: 'Sep 2022 – Jul 2024',
      description: 'Worked on optimizing/studying Fire Whirl Behavior via custom made apparatus.',
      link: 'https://asanchez.ucsd.edu/',
      image: multiscale
    },
    {
      title: 'Sensor Research Engineer',
      company: 'NASA Proposal Writing and Evaluation Experience Academy Program',
      duration: 'May 2022 – Aug 2022',
      description: 'Worked on understanding variety of sensors and implementation into the mapping of the environment without Lidar for proposal based on NASA requirements.',
      link: 'https://www.lspace.asu.edu/applications',
      image: nasa
    },
    {
      title: 'Computer Hardware Engineer',
      company: 'NASA Mission Concept Academy Program',
      duration: 'Sep 2022 – Dec 2022',
      description: 'Worked on implementing Communication Data Handling System within Preliminary Design Review and ensured PDR was approved based on NASA Taxonomy',
      link: 'https://www.lspace.asu.edu/applications',
      image: nasa
    }
  ];

  const csProjects = [
    { 
      title: 'Fire Whirl Fuel Consumption Programs', 
      description: 'Developed Java program for fire whirl data analysis.',
      details: 'Created program for data input, statistics, and heatmap visualization.',
      link: 'https://github.com/akashnaren/Fire-Whirl-Research',
      image: Whirl
    },
    { 
      title: 'Sensor Analysis and Diagram - Proposal', 
      description: 'Worked on terrain identification through motion pattern recognition.',
      details: 'Developed concept for navigation without visual components.',
      link: 'https://drive.google.com/file/d/14K27CWZ6--VPD4KafsgW-Lm2CraexVv-/view',
      image: NWPEEEC
    },
    { 
      title: 'Communication Data Handling System - Preliminary Design Review', 
      description: 'Contributed to asteroid tracking mission concept.',
      details: 'Researched communication system for transmitting asteroid data.',
      link: 'https://drive.google.com/file/d/1yuL5K4KWOEQkNw3uv4jcXuIYfIhT_VL3/view',
      image: MCACAD
    },
    { 
      title: 'Arduino/C++ Actuator/LED Control', 
      description: 'Manipulating Linear Actuator Control for Thrust/Launch Control',
      details: 'Program that allows control of physical actuator and voltage control of LEDs',
      link: 'https://drive.google.com/drive/folders/15-9Y8LL0RoYy-WZaO7lVnAhvtD2UH-V4?usp=sharing',
      image: actuator
    },
    { 
      title: 'Regenerative Cooling MATLAB Program', 
      description: 'MATLAB Program made to take input parameters to show best optimized engine thermals.',
      details: 'The MATLAB simulation models and analyzes the behavior of a rocket\'s trajectory and performance parameters, providing insights into dynamics, stability, and performance under various conditions to inform design decisions. Using MATLAB for its robust numerical computing and data visualization capabilities, the simulation initializes parameters and constants, defines the equations of motion, employs numerical integration techniques, and iterates over time steps to update the rocket\'s state. The results are then visualized and analyzed to optimize the rocket\'s design and ensure successful deployment, similar to the precision required in RTE systems and TDK for reliable aerospace applications.',
      link: 'https://drive.google.com/drive/folders/15-9Y8LL0RoYy-WZaO7lVnAhvtD2UH-V4?usp=sharing',
      image: Regen
    },
    { 
      title: ' Data Acquisition - Firmware/Web Based', 
      description: 'Acquire data and control mechanical components.',
      details: 'The program uses a web-based GUI via an Arduino, utilizing Python, Flask, HTML, CSS, and JavaScript for serial communication and user interaction. The firmware, written in C++ with the Arduino framework, employs FreeRTOS for task management, handling power monitoring, solenoid control, and CAN communication for real-time operations and feedback.',
      link: 'https://github.com',
      image: sole
    },
    { 
      title: 'Python Data Acquisition', 
      description: 'Data Analysis/Manipulation from ADC chips for real time data monitoring',
      details: 'The project aims to provide a web-based interface to display real-time data from ADC chips connected to an Arduino via serial communication. Using Python, HTML, CSS, and JavaScript, the system reads data from the ADC chips, logs it to a CSV file, and displays it on a web interface for real-time monitoring and visualization. ',
      link: 'https://github.com',
      image: daq
    },
    { 
      title: 'NavigateUCSD', 
      description: 'Implemented mapping functionality for campus navigation.',
      details: 'Used Azure Maps and React for enhanced navigation features.',
      link: 'https://navigateucsd.com/',
      image: Navigate
    },
    { 
      title: 'Flight Delays/Cancellations Analysis', 
      description: 'Analyzed flight data using Python and Pandas.',
      details: 'Distilled complex data into actionable insights.',
      link: 'https://github.com/COGS108/Group134_FA23/blob/master/recent-2.ipynb',
      image: one
    },
    { 
      title: 'Chess Mobile Application', 
      description: 'Aided in complex development and product management with client.',
      details: 'The YSC Mobile App Project aims to develop a mobile application that provides an accessible platform for underserved children to learn chess with mentor assistance. Utilizing Python for the backend, HTML, CSS, and JavaScript for the frontend, and React Native for mobile development, the app offers functionalities like account management, real-time gameplay, mentorship sessions, and lesson progression. The project involves detailed planning, client interaction, team coordination, and continuous improvement to ensure a high-quality, user-friendly experience for students and mentors.',
      link: 'https://github.com/TritonSE/YSC-Mobile-Application',
      image: ysc
    },
    { 
      title: 'SDTCA Data Visualization Website', 
      description: 'Aided in complex development and product management with client.',
      details: 'The SDCTA Data Visualization Website Project aims to create an intuitive, interactive platform to enhance user experience and provide accessible statistical information, addressing current limitations. The project involves detailed planning, feature development, and close collaboration with the client and development team to ensure successful implementation and post-launch support. The focus is on robust features like account creation, data visualization, and user feedback to increase engagement and subscription rates.',
      link: 'https://github.com/TritonSE/SDCTA-Data-Visualization-Website',
      image: sdcta
    }
  ];

  const mechProjects = [
    { 
      title: 'Hero Robot Design', 
      description: 'Designed suspension and hinge components using Solidworks.',
      link: 'https://drive.google.com/drive/u/2/folders/1oejSdd-C8_Zy3SlFbyjvBD4_2JPbvg-U',
      image: heroHinge
    },
    { 
      title: 'Balsa Plane Design', 
      description: 'Designed plane using Solidworks.',
      link: 'https://drive.google.com/file/d/1sLYrEc3xf4-CYzFRQlrBNHqSdl3OtcDl/view?usp=drive_link',
      image: balsaCad
    },
    { 
      title: 'Guitar CAD Design', 
      description: 'Designed Guitar using Solidworks.',
      link: 'https://drive.google.com/drive/u/2/folders/19r8-ncs2huf93GgdjPnBdkkJxP9u7OAR',
      image: guitarCad
    },
    { 
      title: 'Fire Whirl Apparatus', 
      description: 'Designed experimental plates for fire whirl studies using Solidworks and AUTOCAD.',
      link: 'https://drive.google.com/drive/u/2/folders/1TBw-k0n8ihaZWIJXB0zGc9gJjjk1pG00',
      image: fireCad
    },
    { 
      title: 'Structural Analysis', 
      description: 'Conducted analysis and modeled acceleration profiles for motor optimization.',
      link: 'https://drive.google.com/drive/u/2/folders/1oejSdd-C8_Zy3SlFbyjvBD4_2JPbvg-U',
      image: heroSuspension
    },
    { 
      title: 'Rocket CAD Design', 
      description: 'Part of MAE 2 Coursework designing an introductory style rocket through CAD.',
      link: 'https://drive.google.com/file/d/1Td4VCpzgpKOtRhGUKJcnsPHpFlgMhWWA/view?usp=sharing',
      image: Rocket
    },
  ];

  useEffect(() => {
    if (activeSection === 'experience') {
      // Reload Twitter widgets
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    }
  }, [activeSection]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0b080d] via-[#0d0912] to-[#0b080d] text-white p-6">
      {/* Navigation Pills */}
      <div className="sticky top-0 z-50 py-4 bg-gradient-to-b from-[#0b080d] to-transparent">
        <div className="max-w-6xl mx-auto">
          <div className="inline-flex rounded-lg bg-white/[0.03] p-1 backdrop-blur-sm">
            {['experience', 'software Engineering', 'mechanical Engineering'].map((section) => (
              <button
                key={section}
                onClick={() => setActiveSection(section)}
                className={`px-6 py-2.5 text-sm font-medium rounded-md transition-all duration-200 ${
                  activeSection === section 
                    ? 'bg-purple-500/20 text-purple-300' 
                    : 'text-gray-400 hover:text-white hover:bg-white/[0.05]'
                }`}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      {activeSection === 'experience' && (
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 mt-8">
            {/* Main Experience Column */}
            <div className="lg:col-span-7 space-y-4">
              <h2 className="text-2xl font-light mb-6">Experience</h2>
              {experiences.map((exp, index) => (
                <a href={exp.link} 
                   key={index}
                   className="block group">
                  <div className="relative pl-16 py-4 rounded-lg bg-white/[0.02] hover:bg-white/[0.04] transition-all">
                    <div className="absolute left-4 top-1/2 -translate-y-1/2 w-8 h-8 rounded-lg bg-white/5 p-1.5
                                  group-hover:scale-110 transition-all duration-300">
                      <img src={exp.image} 
                           alt="" 
                           className="w-full h-full object-contain opacity-70 
                                    group-hover:opacity-100 transition-all" />
                    </div>
                    <div>
                      <h3 className="text-base font-light text-white/90 group-hover:text-purple-300">
                        {exp.title}
                      </h3>
                      <p className="text-sm font-light text-purple-400/60 mt-0.5">{exp.company}</p>
                      <p className="text-xs text-gray-500 mt-0.5">{exp.duration}</p>
                    </div>
                  </div>
                </a>
              ))}
            </div>

            {/* Highlights Column */}
            <div className="lg:col-span-5 space-y-4">
              <h2 className="text-2xl font-light mb-6">Areas of Contribution</h2>
              <div className="space-y-4 sticky top-24">
                {[
                  "1885362544916730257",

                  "1844573295510728857"
                ].map((tweetId) => (
                  <div key={tweetId} 
                       className="bg-white/[0.02] rounded-lg p-2 hover:bg-white/[0.04] transition-all">
                    <blockquote className="twitter-tweet" data-theme="dark">
                      <a href={`https://twitter.com/Tesla/status/${tweetId}`}></a>
                    </blockquote>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Projects Section remains the same */}
      {(activeSection === 'software Engineering' || activeSection === 'mechanical Engineering') && (
        <div className="max-w-6xl mx-auto mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {(activeSection === 'software Engineering' ? csProjects : mechProjects).map((project, index) => (
              <a href={project.link} 
                 key={index}
                 target="_blank"
                 rel="noopener noreferrer" 
                 className="group h-full">
                <div className="h-[120px] p-4 rounded-lg bg-white/[0.02] hover:bg-white/[0.05] 
                              border border-white/[0.05] 
                              hover:border-opacity-30
                              hover:border-current
                              transition-all duration-300
                              ${activeSection === 'software Engineering' ? 'group-hover:border-blue-500' : 'group-hover:border-green-500'}">
                  <div className="flex h-full items-start gap-3">
                    <div className="w-10 h-10 rounded-lg bg-white/5 p-2 flex-shrink-0">
                      <img src={project.image} 
                           alt="" 
                           className="w-full h-full object-contain opacity-70 
                                    group-hover:opacity-100 transition-all" />
                    </div>
                    <div className="flex flex-col justify-between h-full py-0.5 flex-grow">
                      <h3 className={`text-sm font-light text-white/90 
                                    ${activeSection === 'software Engineering' 
                                      ? 'group-hover:text-blue-300' 
                                      : 'group-hover:text-green-300'} 
                                    transition-colors`}>
                        {project.title}
                      </h3>
                      <p className="text-xs text-gray-400/80 line-clamp-2">
                        {project.description}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
